import "./footer.css";


function Footer() {
  return (
    <footer>
        <p>Copyright 2024 Devarl.com</p>
    </footer>
);
}

export default Footer;
